<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="licenseData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No license found with this license id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'licences-dashboard' }"
        >
          license List
        </b-link>
        for other licenses.
      </div>
    </b-alert>

    <b-row
      v-if="licenseData"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <h1 class="text-primary">
                    {{ licenseData.business_name ? licenseData.business_name : licenseData.sacco_name }}
                  </h1>
                </div>
                <h4 class="text-primary">
                  {{ licenseData.business_type ? licenseData.business_type : licenseData.car_license_plate_number }}
                </h4>
                <h5
                  v-if="licenseData.county"
                  class="text-primary"
                >
                  <strong>County: </strong>{{ licenseData.county.name }}
                </h5>
                <h6
                  v-if="licenseData.street_name"
                  class="text-primary"
                >
                  <strong>Business Street Name: </strong>{{ licenseData.street_name }}
                </h6>
                <h6
                  v-if="licenseData.town"
                  class="text-primary"
                >
                  <strong>Business Town: </strong>{{ licenseData.town }}
                </h6>
                <h6
                  v-if="licenseData.building_name"
                  class="text-primary"
                >
                  <strong>Building: </strong>{{ licenseData.building_name }}
                </h6>
              </div>
              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h1 class="invoice-title">
                  License ID:
                  <span class="invoice-number">#{{ licenseData.license_id }}</span>
                </h1>
                <div class="invoice-date-wrapper justify-content-end">
                  <p class="invoice-date-title">
                    Date Issued:
                  </p>
                  <p class="invoice-date">
                    {{ moment(licenseData.updated_at).format("dddd, MMMM Do YYYY") }}
                  </p>
                </div>
                <div class="invoice-date-wrapper justify-content-end">
                  <p class="invoice-date-title">
                    Valid Until:
                  </p>
                  <p class="invoice-date">
                    {{ moment(licenseData.valid_until).format("dddd, MMMM Do YYYY") }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
        <b-card>
          <b-card-header>
            <h4>Update License Location Details</h4>
          </b-card-header>
          <b-card-body>
            <validation-observer
              ref="refUpdateLicenseForm"
              tag="form"
            >
              <b-row>
                <b-col
                  md="6"
                  sm="12"
                >
                  <b-form-group
                    label="Select the county"
                    label-for="enterCounty"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Business County"
                      rules="required"
                    >
                      <b-form-group>
                        <b-form-select
                          v-model="updateLicenseCounty"
                          :options="countiesOptions"
                          size="sm"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                >
                  <b-form-group
                    label="Enter the town"
                    label-for="enterTown"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Business Location Town"
                      rules="required"
                    >
                      <b-form-input
                        id="business town"
                        v-model="updateLicenseTown"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Enter Town"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                >
                  <b-form-group
                    label="Enter the street name"
                    label-for="enterStreetName"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Business Location Street Name"
                      rules="required"
                    >
                      <b-form-input
                        id="business street name"
                        v-model="updateLicenseStreetName"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Enter Street Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                >
                  <b-form-group
                    label="Enter the building name"
                    label-for="enterBuildingName"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Building Name"
                      rules="required"
                    >
                      <b-form-input
                        id="business building name"
                        v-model="updateLicenseBuildingName"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Enter Building Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button
                class="float-right"
                variant="primary"
                :disabled="isLoading"
                @click="updateLicenseDetails"
              >
                {{ !isLoading ? "Update" : "Processing..." }}
                <b-spinner
                  v-if="isLoading"
                  small
                />
              </b-button>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onUnmounted } from '@vue/composition-api/dist/vue-composition-api'
import store from '@/store'
import licenceStoreModule from '@/views/licence/licenceStoreModule'
import router from '@/router'
import useLicenseList from './useLicencesList'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BButton,
  BAlert,
  BLink,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'EditLicense',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BButton,
    BAlert,
    BLink,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormSelect,
    required,
  },
  data() {
    return {
      counties: ['National'],
      allCounties: [],
      countiesOptions: [],
    }
  },
  mounted() {
    this.$http.get('/counties').then(response => {
      this.allCounties = response.data
      response.data.forEach(county => {
        this.counties.push(county.name)
        this.countiesOptions.push({
          value: county.id,
          text: county.name,
        })
      })
    })
  },
  setup() {
    const LICENSE_APP_STORE_MODULE_NAME = 'licenses'

    // Register module
    if (!store.hasModule(LICENSE_APP_STORE_MODULE_NAME)) store.registerModule(LICENSE_APP_STORE_MODULE_NAME, licenceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LICENSE_APP_STORE_MODULE_NAME)) store.unregisterModule(LICENSE_APP_STORE_MODULE_NAME)
    })

    const {
      isLoading,
      licenseData,
      updateLicenseBuildingName,
      updateLicenseCounty,
      updateLicenseStreetName,
      updateLicenseTown,
      refUpdateLicenseForm,
      updateLicenseDetails,
    } = useLicenseList()

    store
      .dispatch('licenses/fetchLicense', {
        id: router.currentRoute.params.id,
      })
      .then(response => {
        licenseData.value = response.data.data
        updateLicenseBuildingName.value = licenseData.value.building_name
        updateLicenseStreetName.value = licenseData.value.street_name
        updateLicenseTown.value = licenseData.value.town
        updateLicenseCounty.value = licenseData.value.county_id
      })
      .catch(error => {
        if (error.response.status === 404) {
          licenseData.value = undefined
        }
      })

    return {
      isLoading,
      licenseData,
      updateLicenseBuildingName,
      updateLicenseCounty,
      updateLicenseStreetName,
      updateLicenseTown,
      refUpdateLicenseForm,
      updateLicenseDetails,
    }
  },
}
</script>

<style scoped>

</style>
